<template>

    <div class="page">
        <a-layout-header class="header">
            <div > <img alt="logo" class="page-header-logo" height="20"
                                      src="~@/assets/logo2.png"></div>
            <a-menu
                    theme="dark"
                    mode="horizontal"
                    :default-selected-keys="['1']"
                    :style="{ lineHeight: '64px',marginLeft:'80px' }"
            >
                <!--<a-menu-item @click="test" key="1">-->
                    <!--下载安卓APP马上就开始-->
                <!--</a-menu-item>-->
                <a-menu-item @click="test2" key="2">
                    马上试用
                </a-menu-item>
                <a-menu-item  @click="test3" key="3">
                    关于我们
                </a-menu-item>
            </a-menu>

        </a-layout-header>


        <div style="background-color: #ececec; padding-left:0px; padding-bottom:10px; ">

            <img src="../../assets/home3.jpg" width=100%;/>

            <a-card style="background-color: #ececec; padding: 20px; width:100%; ">
                <a-form-model :model="dFormData" :rules="dRules" layout="vertical" ref="myForm">
                    <a-form-model-item class="form-item" label="管理员账号" prop="phone" ref="phone">
                        <a-input placeholder="请输入手机号码" v-model="dFormData.phone">
                            <a-icon slot="prefix" style="color:rgba(0,0,0,.25)" type="user"/>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item class="form-item" label="登录密码" prop="password">
                        <a-input placeholder="请输入密码" type="password" v-model="dFormData.password">
                            <a-icon slot="prefix" style="color:rgba(0,0,0,.25)" type="lock"/>
                        </a-input>
                    </a-form-model-item>
                    <!--<a-form-model-item class="form-item forgetpassword">-->
                        <!--<div @click="retrievePassword" class="forgetpassword-link">忘记密码</div>-->
                    <!--</a-form-model-item>-->
                    <a-form-model-item class="form-item">
                        <a-button @click="submitForm('myForm')" type="primary">登录</a-button>
                    </a-form-model-item>
                    <!--<a-form-model-item class="form-item">-->
                        <!--<a-button @click="register" type="primary">注册</a-button>-->
                    <!--</a-form-model-item>-->
                </a-form-model>
                <br>
                <hr>
                <br>
                <a-card-meta title="温馨提示:">
                    <template slot="description">
                        此处为管理员入口，请用户通过安卓APP注册，页首有APP下载的入口。谢谢！
                    </template>
                </a-card-meta>
            </a-card>


        </div>

        <a-layout-footer>@ All Rights Reserved. Powered by pptgame.online<br>
            <a href="http://beian.miit.gov.cn" target="_blank" title=""> 粤ICP备17115458号</a></a-layout-footer>
    </div>


</template>


<script>

    import Vue from 'vue'
    import {mapActions} from "vuex"
    import {strIsEmpty} from '@/utils/my-utils';
    import Header from "ant-design-vue/es/calendar/Header";
    import AFormItem from "ant-design-vue/es/form/FormItem";


    export default {
        name: "Login",
        components: {
            AFormItem,
            Header

        },
        data() {
            return {
                dFormData: {
                    phone: '',
                    smsCode: '',
                    password: '',
                },
                dRules: {
                    phone: [
                        {required: true, message: '请输入手机号码', trigger: 'blur'},
                    ],
                    /*smsCode: [
                        { required: true, message: '请输入手机验证码', trigger: 'blur' },
                    ],*/
                    password: [
                        {required: true, message: '请输入登录密码', trigger: 'blur'},
                        {min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur'}
                    ]
                }
            }
        },
        components: {},
        inject: ['setHeaderIsShow'],                                  //调用父亲方法
        methods: {
            ...mapActions(["userLogin", "userLogout"]),
            pageInit: function () {
                this.setHeaderIsShow(false);
            },
            getSmsCode: function () {

            },
            submitForm: function (formName) {
                let that = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        that.login();
                    } else {
                        return false;
                    }
                });
            },
            login: function () {
                let that = this;
                /*if(strIsEmpty(this.dSmsCode)){
                    Toast('请输入手机验证码');
                    return;
                }*/
                let params = {
                    remember_me: 0,
                    account: this.dFormData.phone,
                    smsCode: '',
                    password: this.dFormData.password,
                    loginType: 1
                };
                this.userLogin(params).then((res) => {
                    console.log('userLogin, result = ' + JSON.stringify(res));
                    that.loginSuccess()
                }).catch((err) => {
                    that.loginFailed(err);
                });
            },
            loginSuccess: function () {
                //this.$router.push({ name: "Index" });
                this.$router.push({path: "/main/analysis"}).catch(() => {
                    console.log('登录跳转首页出错')
                });
            },
            loginFailed: function (err) {
                //Toast('登陆错误 ' + JSON.stringify(err));
                // // code: 1=该手机没有注册, 2=无效用户, 3=手机验证码错误, 4=密码错误
                switch (err.code) {
                    case 1:
                        this.$message.error(err.message);
                        break;
                    case 2:
                        this.$message.error(err.message);
                        break;
                    case 3:
                        this.$message.error(err.message);
                        break;
                    case 4:
                        this.$message.error(err.message);
                        break;
                }
            },
            register: function () {
                this.$router.push({name: "Register"});
            },
            retrievePassword: function () {
                this.$router.push({name: "RetrievePassword"});
            },
            test: function () {
                // this.$router.push({name: "Register"});
                window.location.href = 'http://www.pptgame.online:8088/android/pg1.apk';
            },
            test2: function () {
                // this.$router.push({name: "Register"});
                window.location.href = 'http://www.pptgame.online:8088/videoexport/trynow.php';
            },
            test3: function () {
                // this.$router.push({name: "Register"});
                window.location.href = 'http://www.pptgame.online:8088/videoexport/aboutus.php';
            },
        },
        created() {
            this.pageInit();
        },
    }
</script>

<style lang="less" scoped>

    .page {
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px !important;
        background-color: #ffffff;

        .form-item {
            max-width: 300px;
        }

        .forgetpassword {
            text-align: right;

            .forgetpassword-link {
                color: deepskyblue;
            }
        }
    }

</style>
